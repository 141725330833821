<ul class="navbar-nav">
  <li class="nav-item">
    <a class="nav-link" href="javascript:;" (click)="toggleSidebar()">
      <i class="fas fa-bars"></i>
    </a>
  </li>
  <li class="nav-item d-none d-sm-inline-block">
    <a class="nav-link" [routerLink]="['home']">
      {{ "HomePage" | localize }}
    </a>
  </li>
  <li class="nav-item d-none d-sm-inline-block">
    <a class="nav-link" [routerLink]="['about']">
      {{ "About" | localize }}
    </a>
  </li>
</ul>
