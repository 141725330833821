<div class="main-content">
  <div class="content row">
    <div class="logo-top-div">
      <a href="https://www.optimisare.com/">
        <img class="logo-top" src="/assets/img/logo.svg" alt="Optimisare Software for business workflow optimisation"/>
      </a>
    </div>
    <div class="header-content">
      <div class="top-menu-items row" *ngIf="isDesktop; else mobileMenu">
        <a class="top-menu-item" [routerLink]="['/home']" (click)="onLinkClick()">
          <div [ngClass]="{ 'active': isActive('/home') }">{{ "Home" | localize }}</div>
        </a>
        <a class="top-menu-item" [routerLink]="['/about-us']" (click)="onLinkClick()">
          <div [ngClass]="{ 'active': isActive('/about-us') }">{{ "AboutUs" | localize }}</div>
        </a>
        <a class="top-menu-item" [routerLink]="['/products']" (click)="onLinkClick()">
          <div [ngClass]="{ 'active': isActive('/products') }">{{ "Products" | localize }}</div>
        </a>
        <a class="top-menu-item" [routerLink]="['/contact-us']" (click)="onLinkClick()">
          <div [ngClass]="{ 'active': isActive('/contact-us') }">{{ "ContactUs" | localize }}</div>
        </a>
      </div>
      <ng-template #mobileMenu>
        <i class="fas fa-bars" (click)="toggleMobileMenu()"></i>
        <div class="mobile-menu" *ngIf="showMobileMenu">
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('/home') }">
            <a [routerLink]="['/home']" (click)="onLinkClick()">{{ "Home" | localize }}</a>
          </div>
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('/about-us') }">
            <a [routerLink]="['/about-us']" (click)="onLinkClick()">{{ "AboutUs" | localize }}</a>
          </div>
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('/products') }">
            <a [routerLink]="['/products']" (click)="onLinkClick()">{{ "Products" | localize }}</a>
          </div>
          <div class="top-menu-item" [ngClass]="{ 'active': isActive('/contact-us') }">
            <a [routerLink]="['/contact-us']" (click)="onLinkClick()">{{ "ContactUs" | localize }}</a>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="languages-container">
      <app-public-languages></app-public-languages>
    </div>
  </div>
</div>
