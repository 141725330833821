import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PublicRoutingModule} from './public-routing.module';
import {AboutUsComponent} from './about-us/about-us.component';
import {ContactUsComponent} from './contact-us/contact-us.component';
import {HomeComponent} from './home/home.component';
import {ProductsComponent} from './products/products.component';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {MatInput, MatInputModule} from '@angular/material/input';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {SharedModule} from '@shared/shared.module';
import {NgxCaptchaModule} from '@node_modules/ngx-captcha';


@NgModule({
  declarations: [
    AboutUsComponent,
    ContactUsComponent,
    HomeComponent,
    ProductsComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    PublicRoutingModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SharedModule,
    NgxCaptchaModule
  ]
})
export class PublicModule {
}
