import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {SharedModule} from '@shared/shared.module';

@Component({
  selector: 'app-bottom-menu',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './bottom-menu.component.html',
  styleUrl: './bottom-menu.component.css'
})
export class BottomMenuComponent {
  date: Date = new Date();
  constructor() {
  }
}
