<form
  class="form-horizontal"
  autocomplete="off"
  #createTenantForm="ngForm"
  (ngSubmit)="save()"
>
  <abp-modal-header
    [title]="'CreateNewTenant' | localize"
    (onCloseClick)="bsModalRef.hide()"
  ></abp-modal-header>
  <div class="modal-body">
    <div class="form-group row required">
      <label class="col-md-3 col-form-label" for="tenancyName">
        {{ "TenancyName" | localize }}
      </label>
      <div class="col-md-9">
        <input
          type="text"
          class="form-control"
          name="tenancyName"
          id="tenancyName"
          minlength="2"
          maxlength="64"
          required
          [(ngModel)]="tenant.tenancyName"
          #tenancyNameModel="ngModel"
          #tenancyNameEl
        />
        <abp-validation-summary
          [control]="tenancyNameModel"
          [controlEl]="tenancyNameEl"
        ></abp-validation-summary>
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-md-3 col-form-label" for="name">
        {{ "Name" | localize }}
      </label>
      <div class="col-md-9">
        <input
          type="text"
          class="form-control"
          name="name"
          id="name"
          maxlength="128"
          required
          [(ngModel)]="tenant.name"
          #nameModel="ngModel"
          #nameEl
        />
        <abp-validation-summary
          [control]="nameModel"
          [controlEl]="nameEl"
        ></abp-validation-summary>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label" for="connectionString">
        {{ "DatabaseConnectionString" | localize }}
      </label>
      <div class="col-md-9">
        <input
          type="text"
          class="form-control"
          name="connectionString"
          id="connectionString"
          maxlength="1024"
          [(ngModel)]="tenant.connectionString"
        />
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-md-3 col-form-label" for="adminEmailAddress">
        {{ "AdminEmailAddress" | localize }}
      </label>
      <div class="col-md-9">
        <input
          type="email"
          class="form-control"
          name="adminEmailAddress"
          id="adminEmailAddress"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$"
          maxlength="256"
          required
          [(ngModel)]="tenant.adminEmailAddress"
          #adminEmailAddressModel="ngModel"
          #adminEmailAddressEl
        />
        <abp-validation-summary
          [control]="adminEmailAddressModel"
          [controlEl]="adminEmailAddressEl"
        ></abp-validation-summary>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">
        {{ "IsActive" | localize }}
      </label>
      <div class="col-md-9">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            name="isActive"
            id="isActive"
            [(ngModel)]="tenant.isActive"
          />
          <label class="custom-control-label mt-2" for="isActive"></label>
        </div>
      </div>
    </div>
    <p class="text-center text-info mb-0">
      {{ "DefaultPasswordIs" | localize: "123qwe" }}
    </p>
  </div>
  <abp-modal-footer
    [cancelDisabled]="saving"
    [saveDisabled]="!createTenantForm.form.valid || saving"
    (onCancelClick)="bsModalRef.hide()"
  ></abp-modal-footer>
</form>
