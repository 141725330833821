<li class="nav-item dropdown" dropdown>
  <a href="javascript:;" class="nav-link" dropdownToggle>
    <i class="d-inline-block {{ currentLanguage.icon }}"></i>
    <span class="d-none d-md-inline-block ml-1">
      {{ currentLanguage.displayName }}
    </span>
  </a>
  <div class="dropdown-menu dropdown-menu-right p-0" *dropdownMenu>
    <ng-container *ngFor="let language of languages">
      <a
        *ngIf="language.name != currentLanguage.name"
        class="dropdown-item"
        href="javascript:;"
        (click)="changeLanguage(language.name)"
      >
        <i class="d-inline-block {{ language.icon }} mr-1"></i>
        {{ language.displayName }}
      </a>
    </ng-container>
  </div>
</li>
