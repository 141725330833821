import { Component, ChangeDetectionStrategy, OnInit, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { filter as _filter } from 'lodash-es';
import { AppComponentBase } from '@shared/app-component-base';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-public-languages',
  standalone: true,
  imports: [CommonModule, BsDropdownModule],
  templateUrl: './public-languages.component.html',
  styleUrls: ['./public-languages.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicLanguagesComponent extends AppComponentBase implements OnInit {
  languages: abp.localization.ILanguageInfo[];
  currentLanguage: abp.localization.ILanguageInfo;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.languages = _filter(this.localization.languages, (l) => !l.isDisabled);
    // console.log('languages: ', this.languages);
    this.currentLanguage = this.localization.currentLanguage;
  }

  changeLanguage(languageName: string): void {
    abp.utils.setCookieValue(
      'Abp.Localization.CultureName',
      languageName,
      new Date(new Date().getTime() + 5 * 365 * 86400000), // 5 years
      abp.appPath
    );

    location.reload();
  }
}
