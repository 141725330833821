<div class="main-content">
  <div class="content-space">
    <div class="content-header">{{ "AboutUs" | localize }}</div>
    <div class="content">
      <img src="/assets/img/standard-quality-control-concept-m-scaled.jpg" class="content-image" alt="standard-quality-control-concept"/>
      {{ "AboutUs1" | localize }}
    </div>
    <div class="content">
      {{ "AboutUs2" | localize }}
    </div>
    <div class="content">
      {{ "AboutUs3" | localize }}
    </div>
    <div class="content">
      <ul>
        <li>{{ "AboutUs4" | localize }}</li>
        <li>{{ "AboutUs5" | localize }}</li>
        <li>{{ "AboutUs6" | localize }}</li>
        <li>{{ "AboutUs7" | localize }}</li>
        <li>{{ "AboutUs8" | localize }}</li>
        <li>{{ "AboutUs9" | localize }}</li>
        <li>{{ "AboutUs10" | localize }}</li>
        <li>{{ "AboutUs11" | localize }}</li>
      </ul>
    </div>
    <div class="content">
      {{ "AboutUs12" | localize }}
    </div>
    <div class="content">
      {{ "AboutUs13" | localize }}<a class="top-menu-item" [routerLink]="['/contact-us']">{{ "ContactUs" | localize }}</a>{{ "AboutUs13.1" | localize }}
    </div>
    <div class="content">
      {{ "AboutUs14" | localize }}
       <br>
      {{ "AboutUs15" | localize }}
    </div>
  </div>
</div>
