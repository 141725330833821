<div class="user-panel mt-3 pb-3 mb-3 d-flex">
  <div class="image">
    <img
      src="assets/img/user.png"
      class="img-circle elevation-2"
      alt="User Image"
    />
  </div>
  <div class="info">
    <a class="d-block" href="javascript:;">{{ shownLoginName }}</a>
  </div>
</div>
