<div [@routerTransition]>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <h1>{{ "Tenants" | localize }}</h1>
                </div>
                <div class="col-6 text-right">
                    <a href="javascript:;" class="btn bg-blue" (click)="createTenant()">
                        <i class="fa fa-plus-square"></i>
                        {{ "Create" | localize }}
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="content px-2">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-6">&emsp;</div>
                        <div class="col-md-6">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button
                                            type="button"
                                            class="btn bg-blue"
                                            (click)="getDataPage(1)"
                                    >
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                                <input
                                        type="text"
                                        class="form-control"
                                        name="keyword"
                                        [placeholder]="'SearchWithThreeDot' | localize"
                                        [(ngModel)]="keyword"
                                        (keyup.enter)="getDataPage(1)"
                                />
                                <div class="input-group-append">
                                    <button
                                            type="button"
                                            class="btn btn-default"
                                            (click)="advancedFiltersVisible = !advancedFiltersVisible"
                                    >
                                        <i
                                                class="fas"
                                                [class.fa-angle-up]="advancedFiltersVisible"
                                                [class.fa-angle-down]="!advancedFiltersVisible"
                                        ></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="advancedFiltersVisible" class="card mb-0 mt-1">
                        <div class="card-body">
                            <form class="form-horizontal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group row mb-0">
                                            <label class="col-md-3 col-form-label">
                                                {{ "IsActive" | localize }}
                                            </label>
                                            <div class="col-md-9 pt-2">
                                                <div class="custom-control custom-radio d-inline">
                                                    <input
                                                            type="radio"
                                                            class="custom-control-input"
                                                            id="isActiveAll"
                                                            name="isActive"
                                                            [(ngModel)]="isActive"
                                                            [value]="undefined"
                                                            checked
                                                    />
                                                    <label class="custom-control-label" for="isActiveAll">
                                                        {{ "All" | localize }}
                                                    </label>
                                                </div>
                                                <div class="custom-control custom-radio d-inline mx-3">
                                                    <input
                                                            type="radio"
                                                            class="custom-control-input"
                                                            id="isActiveActive"
                                                            name="isActive"
                                                            [(ngModel)]="isActive"
                                                            [value]="true"
                                                    />
                                                    <label
                                                            class="custom-control-label"
                                                            for="isActiveActive"
                                                    >
                                                        {{ "Yes" | localize }}
                                                    </label>
                                                </div>
                                                <div class="custom-control custom-radio d-inline">
                                                    <input
                                                            type="radio"
                                                            class="custom-control-input"
                                                            id="isActivePassive"
                                                            name="isActive"
                                                            [(ngModel)]="isActive"
                                                            [value]="false"
                                                    />
                                                    <label
                                                            class="custom-control-label"
                                                            for="isActivePassive"
                                                    >
                                                        {{ "No" | localize }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <button
                                    type="button"
                                    class="btn bg-blue"
                                    (click)="getDataPage(1)"
                            >
                                {{ "Search" | localize }}
                            </button>
                            <button
                                    type="button"
                                    class="btn btn-default float-right"
                                    (click)="clearFilters()"
                            >
                                {{ "Clear" | localize }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered" [busy]="isTableLoading">
                            <thead class="bg-light">
                            <tr>
                                <th>{{ "TenancyName" | localize }}</th>
                                <th>{{ "Name" | localize }}</th>
                                <th>{{ "IsActive" | localize }}</th>
                                <th style="width: 200px;">{{ "Actions" | localize }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    *ngFor="
                  let tenant of tenants
                    | paginate
                      : {
                          id: 'server',
                          itemsPerPage: pageSize,
                          currentPage: pageNumber,
                          totalItems: totalItems
                        }
                "
                            >
                                <td>{{ tenant.tenancyName }}</td>
                                <td>{{ tenant.name }}</td>
                                <td>
                                    <div class="custom-control custom-checkbox">
                                        <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                disabled
                                                [checked]="tenant.isActive"
                                        />
                                        <label class="custom-control-label"></label>
                                    </div>
                                </td>
                                <td>
                                    <button
                                            type="button"
                                            class="btn btn-sm bg-secondary"
                                            (click)="editTenant(tenant)"
                                    >
                                        <i class="fas fa-pencil-alt"></i>
                                        {{ "Edit" | localize }}
                                    </button>
                                    <button
                                            type="button"
                                            class="btn btn-sm bg-danger mx-2"
                                            (click)="delete(tenant)"
                                    >
                                        <i class="fas fa-trash"></i>
                                        {{ "Delete" | localize }}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer table-card-footer bg-light border-top">
                    <div class="row">
                        <div class="col-sm-4 col-12 text-sm-left text-center">
                            <button class="btn btn-secondary" (click)="refresh()">
                                <i class="fas fa-redo-alt"></i>
                            </button>
                        </div>
                        <div class="col-sm-4 col-12 text-center">
                            <p class="mb-0 my-2">
                                {{ "TotalRecordsCount" | localize: totalItems }}
                            </p>
                        </div>
                        <div class="col-sm-4 col-12">
                            <div class="float-sm-right m-auto">
                                <abp-pagination-controls
                                        id="server"
                                        (pageChange)="getDataPage($event)"
                                >
                                </abp-pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
