<div class="main-content">
  <div class="content-space">
    <div class="content-header">{{ "Products1" | localize }}</div>
    <div class="content">
      <a href="http://www.ufd.optimisare.com/" target="_blank" style="color: #FCAF3B; text-decoration: underline; font-weight: bold;">UpForDrive</a> –
      {{ "Products2" | localize }}
    </div>
    <div class="content-h1">
      {{ "Products3" | localize }}
    </div>
    <div class="content">
      {{ "Products4" | localize }}
    </div>
    <div class="content">
      {{ "Products5" | localize }}
    </div>
    <div class="content">
      {{ "Products6" | localize }}
    </div>
    <div class="content">
      {{ "Products7" | localize }}
    </div>
    <div class="content">
      {{ "Products8" | localize }}
    </div>
    <div class="content">
      {{ "Products9" | localize }}
    </div>
    <div class="content">
      {{ "Products10" | localize }}
    </div>
    <div class="content">
      {{ "Products11" | localize }}
    </div>
    <div class="content">
      {{ "Products12" | localize }}
    </div>
  </div>
</div>
