<div *ngIf="isMultiTenancyEnabled" class="text-center tenant-change-component">
  <span>
    {{ "CurrentTenant" | localize }}:
    <span *ngIf="tenancyName" title="{{ name }}">
      <strong>{{ tenancyName }}</strong>
    </span>
    <span *ngIf="!tenancyName">{{ "NotSelected" | localize }}</span>
    (<a href="javascript:;" (click)="showChangeModal()">
      {{ "Change" | localize }} </a
    >)
  </span>
</div>
