<div [@routerTransition]>
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <h1>{{ "UpdatePassword" | localize }}</h1>
        </div>
      </div>
    </div>
  </section>
  <section class="content px-2">
    <div class="container-fluid">
      <div class="card">
        <form
          class="form-horizontal"
          autocomplete="off"
          #changePasswordForm="ngForm"
          (ngSubmit)="changePassword()"
        >
          <div class="card-body">
            <div class="modal-body">
              <div class="form-group row required">
                <label class="col-md-3 col-form-label" for="currentPassword">
                  {{ "CurrentPassword" | localize }}
                </label>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    name="currentPassword"
                    id="currentPassword"
                    required
                    minlength="2"
                    maxlength="32"
                    [(ngModel)]="changePasswordDto.currentPassword"
                    #currentPasswordModel="ngModel"
                    #currentPasswordEl
                  />
                  <abp-validation-summary
                    [control]="currentPasswordModel"
                    [controlEl]="currentPasswordEl"
                  ></abp-validation-summary>
                </div>
              </div>
              <div class="form-group row required">
                <label class="col-md-3 col-form-label" for="newPassword">
                  {{ "NewPassword" | localize }}
                </label>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    name="newPassword"
                    id="newPassword"
                    required
                    minlength="2"
                    maxlength="32"
                    validateEqual="confirmNewPassword"
                    reverse="true"
                    pattern="(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$"
                    [(ngModel)]="changePasswordDto.newPassword"
                    #newPasswordModel="ngModel"
                    #newPasswordEl
                  />
                  <abp-validation-summary
                    [control]="newPasswordModel"
                    [controlEl]="newPasswordEl"
                    [customValidationErrors]="newPasswordValidationErrors"
                  ></abp-validation-summary>
                </div>
              </div>
              <div class="form-group row required">
                <label class="col-md-3 col-form-label" for="confirmNewPassword">
                  {{ "ConfirmNewPassword" | localize }}
                </label>
                <div class="col-md-9">
                  <input
                    type="password"
                    class="form-control"
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    required
                    minlength="2"
                    maxlength="32"
                    validateEqual="newPassword"
                    reverse="false"
                    ngModel
                    #confirmNewPasswordModel="ngModel"
                    #confirmNewPasswordEl
                  />
                  <abp-validation-summary
                    [control]="confirmNewPasswordModel"
                    [controlEl]="confirmNewPasswordEl"
                    [customValidationErrors]="
                      confirmNewPasswordValidationErrors
                    "
                  ></abp-validation-summary>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer justify-content-between">
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!changePasswordForm.form.valid || saving"
            >
              {{ "Save" | localize }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
