import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppModule} from '@app/app.module';
import {AccountModule} from '../../../account/account.module';
import {PublicModule} from '../../public.module';
import {PublicLanguagesComponent} from '../../public-languages/public-languages.component';
import {SharedModule} from '@shared/shared.module';
import {Router, RouterLink} from '@angular/router';


@Component({
  selector: 'app-top-menu',
  standalone: true,
  imports: [CommonModule, AppModule, AccountModule, PublicModule, PublicLanguagesComponent, SharedModule, RouterLink],
  templateUrl: './top-menu.component.html',
  styleUrl: './top-menu.component.css'
})
export class TopMenuComponent {
  isDesktop: boolean = window.innerWidth > 1011;
  showMobileMenu = false;

  constructor(private router: Router) {
    window.addEventListener('resize', () => {
      this.isDesktop = window.innerWidth > 1011;
    });
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  // Функция для проверки активной ссылки
  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  onLinkClick() {
    if (this.isDesktop) {
      return; // Для десктопа ничего не делаем
    }
    this.toggleMobileMenu(); // Для мобильного меню вызываем метод toggleMobileMenu()
  }
}
