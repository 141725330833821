<div class="card-footer footer-bg-color">
  <div class="content">
    <div class="footer-left">
      {{ "Bottom1" | localize }}
      <br>
      {{ "Bottom2" | localize }}
    </div>
    <div class="footer-right">
      Copyright © {{ date.getFullYear() }} Optimisare Ltd.
      <br>
      {{ "Bottom3" | localize }}
    </div>
  </div>
</div>
