<form
  class="form-horizontal"
  autocomplete="off"
  #createRoleForm="ngForm"
  (ngSubmit)="save()"
>
  <abp-modal-header
    [title]="'CreateNewRole' | localize"
    (onCloseClick)="bsModalRef.hide()"
  ></abp-modal-header>
  <div class="modal-body">
    <tabset>
      <tab [heading]="'Details' | localize" class="pt-3 px-2">
        <div class="form-group row required">
          <label class="col-md-3 col-form-label" for="name">
            {{ "Name" | localize }}
          </label>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              name="name"
              id="name"
              required
              minlength="2"
              maxlength="32"
              [(ngModel)]="role.name"
              #nameModel="ngModel"
              #nameEl
            />
            <abp-validation-summary
              [control]="nameModel"
              [controlEl]="nameEl"
            ></abp-validation-summary>
          </div>
        </div>
        <div class="form-group row required">
          <label class="col-md-3 col-form-label" for="displayName">
            {{ "DisplayName" | localize }}
          </label>
          <div class="col-md-9">
            <input
              type="text"
              class="form-control"
              name="displayName"
              id="displayName"
              required
              minlength="2"
              maxlength="32"
              [(ngModel)]="role.displayName"
              #displayNameModel="ngModel"
              #displayNameEl
            />
            <abp-validation-summary
              [control]="displayNameModel"
              [controlEl]="displayNameEl"
            ></abp-validation-summary>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label" for="description">
            {{ "RoleDescription" | localize }}
          </label>
          <div class="col-md-9">
            <textarea
              type="text"
              class="form-control"
              name="description"
              id="description"
              [(ngModel)]="role.description"
            >
            </textarea>
          </div>
        </div>
      </tab>
      <tab [heading]="'Permissions' | localize" class="pt-3 px-2">
        <div class="form-group row mb-0">
          <ng-container *ngFor="let permission of permissions; let i = index">
            <div class="col-md-6">
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [id]="'permission_' + i"
                  [checked]="isPermissionChecked(permission.name)"
                  (change)="onPermissionChange(permission, $event)"
                />
                <label class="custom-control-label" [for]="'permission_' + i">
                  {{ permission.displayName }}
                </label>
              </div>
            </div>
          </ng-container>
        </div>
      </tab>
    </tabset>
  </div>
  <abp-modal-footer
    [cancelDisabled]="saving"
    [saveDisabled]="!createRoleForm.form.valid || saving"
    (onCancelClick)="bsModalRef.hide()"
  ></abp-modal-footer>
</form>
