import { Component, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CustomerInquiryDto, CustomerInquirySServiceProxy } from '@shared/service-proxies/service-proxies';
import { NotifyService } from 'abp-ng2-module';
import { AppComponentBase } from '@shared/app-component-base';
import { Router } from '@angular/router';
import {ReCaptcha2Component} from 'ngx-captcha';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent extends AppComponentBase {
  @ViewChild('contactUsForm') contactUsForm: NgForm;
  @ViewChild(ReCaptcha2Component) recaptchaComponent: ReCaptcha2Component;
  newMessage: CustomerInquiryDto = new CustomerInquiryDto();
  siteKey = '6LeCQAEqAAAAAGyFr2F6DgFiRIvhYnbRgda63sUD'; // Замените на ваш site key

  constructor(
    injector: Injector,
    private customerInquirySServiceProxy: CustomerInquirySServiceProxy,
    private notifyService: NotifyService,
    private router: Router,
  ) {
    super(injector);
    window.scrollTo(0, 0);
  }

  handleCaptchaResponse(response: string): void {
    this.newMessage.recaptchaToken = response;
  }

  submit() {
    if (this.contactUsForm.invalid || !this.newMessage.recaptchaToken) {
      this.notifyService.error(this.l('AllMandatoryFieldsMustBeCompleted'));
      return;
    }
    console.log('newMessage: ', this.newMessage);
    this.customerInquirySServiceProxy.create(this.newMessage).subscribe(
      (response) => {
        console.log('POST request successful', response);
        this.notifyService.success(this.l('YourMessageHasBeenSuccessfullySent'));
        this.router.navigate(['/home']);
      },
      (error) => {
        this.notifyService.error('Error: ', error.error);
        console.error('POST request error', error);
      }
    );
  }
}
