import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {TopMenuComponent} from './layout/top-menu/top-menu.component';
import {BottomMenuComponent} from './layout/bottom-menu/bottom-menu.component';

@Component({
  selector: 'app-public',
  standalone: true,
  imports: [CommonModule, TopMenuComponent, BottomMenuComponent, RouterModule],
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent { }
