<form
  class="form-horizontal"
  autocomplete="off"
  #resetPasswordModal="ngForm"
  (ngSubmit)="resetPassword()"
>
  <abp-modal-header
    [title]="'ResetPassword' | localize"
    (onCloseClick)="bsModalRef.hide()"
  ></abp-modal-header>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-9 offset-md-3">
        <p class="text-info mb-1">
          {{ "ResetPasswordStepOneInfo" | localize }}
        </p>
      </div>
    </div>
    <div class="form-group row required">
      <label class="col-md-3 col-form-label" for="adminPassword">
        {{ "AdminPassword" | localize }}
      </label>
      <div class="col-md-9">
        <input
          type="password"
          class="form-control"
          id="adminPassword"
          name="adminPassword"
          required
          [(ngModel)]="resetPasswordDto.adminPassword"
          #adminPasswordModel="ngModel"
          #adminPasswordEl
        />
        <abp-validation-summary
          [control]="adminPasswordModel"
          [controlEl]="adminPasswordEl"
        ></abp-validation-summary>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9 offset-md-3">
        <p class="text-info mb-1">
          {{ "ResetPasswordStepTwoInfo" | localize }}
        </p>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-3 col-form-label" for="newPassword">
        {{ "NewPassword" | localize }}
      </label>
      <div class="col-md-9">
        <input
          type="text"
          class="form-control"
          name="NewPassword"
          id="newPassword"
          readonly
          [ngModel]="resetPasswordDto.newPassword"
        />
      </div>
    </div>
  </div>
  <abp-modal-footer
    [cancelDisabled]="isLoading"
    [saveDisabled]="!resetPasswordModal.form.valid || isLoading"
    (onCancelClick)="bsModalRef.hide()"
  ></abp-modal-footer>
</form>
