<div class="main-content">
  <div class="content-space">
    <form #contactUsForm="ngForm">

      <div class="content-header">{{ "ContactUs1" | localize }}</div>
      <div class="row">
        <!-- Name -->
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>{{ "ContactUs2" | localize }}</mat-label>
          <input matInput name="name" [(ngModel)]="newMessage.name" required maxlength="30">
        </mat-form-field>

        <!-- Email -->
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>{{ "ContactUs3" | localize }}</mat-label>
          <input matInput name="email" [(ngModel)]="newMessage.email" required maxlength="30">
        </mat-form-field>

        <!-- Phone -->
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>{{ "ContactUs4" | localize }}</mat-label>
          <input matInput name="phone" [(ngModel)]="newMessage.mobile" required maxlength="30">
        </mat-form-field>

        <!-- Subject -->
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>{{ "ContactUs5" | localize }}</mat-label>
          <input matInput name="subject" [(ngModel)]="newMessage.subject" required maxlength="30">
        </mat-form-field>

        <!-- Message -->
        <mat-form-field>
          <mat-label>{{ "ContactUs6" | localize }}</mat-label>
          <textarea matInput name="message" [rows]="5" [(ngModel)]="newMessage.message" required maxlength="300"></textarea>
        </mat-form-field>

      </div>

      <!-- Интеграция ngx-captcha -->
      <ngx-recaptcha2 [siteKey]="siteKey" (success)="handleCaptchaResponse($event)"></ngx-recaptcha2>

      <button mat-raised-button type="submit" class="btn btn-toolbar pull-right" style="margin-left: 20px;" (click)="submit()">
        {{ "Submit" | localize }}
      </button>
    </form>
  </div>
</div>
